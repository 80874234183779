import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      primaryLight: '#FFFFFF',
      neutralLight1: '#FFFBF6',
      neutralLight2: '#F7F2ED',
      neutralLight3: '#FDD7A1',
      neutralLight4: '#F5AB74',
      primaryDark: '#242424',
      danger: '#ED0F36',
    },
  },
  fontFamily: {
    heading: "'Caudex', serif",
    paragraph: "'Cormorant Garamond', serif",
    label: "'Lato', sans-serif",
    handwritten: "'Allison', cursive",
  },
}

export type Styles = typeof styles

export const styles = {
  h1: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 5rem;

    @media (max-width: 1199px) {
      font-size: 3.125rem;
    }
    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  `,
  h2: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 3.125rem;

    @media (max-width: 1199px) {
      font-size: 1.875rem;
      line-height: 2.5rem;
    }
  `,
  h3: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.heading};
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 2.5rem;
  `,
  label: css`
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.label};
    font-weight: 600;
    font-size: 0.8125rem;
    letter-spacing: 0.1em;
    line-height: 1.125rem;
    text-align: left;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      width: 9.25rem;
      height: 0.0625rem;
      margin-right: 0.8125rem;
      background: ${theme.colors.variants.primaryDark};

      @media (max-width: 1199px) {
        width: 3.125rem;
      }
    }
  `,
  description: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.paragraph};
    font-weight: normal;
    font-size: 1.5625rem;
    line-height: 2.5rem;

    @media (max-width: 1199px) {
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }

    @supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
      &::first-letter {
        -webkit-initial-letter: 2.4;
        initial-letter: 2.4;
        padding: 0.625rem;
        margin-top: -1rem;
      }
    }
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
